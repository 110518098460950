import React from 'react'

import Layout from '../components/layout'
import Head from '../components/head'

import { trackCustomEvent } from "gatsby-plugin-google-analytics"

import '../styles/grid.scss'
import '../styles/index.scss'
import cubuk1 from "../images/cubuk1.png"
import cubuk2 from "../images/cubuk2.png"
import surudenAyril from "../images/surudenayril.png"
import sanaOzel from "../images/sanaozel.png"
import hediyelerKazan from "../images/hediyeler-kazan.svg"
import hediye1 from "../images/5kisi.png"
import hediye2 from "../images/10kisi.png"
import hediye3 from "../images/15kisi.png"
import hediye4 from "../images/20kisi.png"
import soru from "../images/soru.svg"
import hediyeNasil from "../images/hediye-nasil.png"

import iosButton from "../images/iosbutton.png"
import androidButton from "../images/androidbutton.png"


const CommunityPage = () => {
 
  return(
 
    <Layout >
      <Head title="Ahali" description="Ahaliye katıl" />
        <div className="row ahali">
      <div className="ctaBanner ">
          
        <div className="row ahali-hero">
            <div className="container">
            <div className="col-1 hidden-sm"></div>
            <div className="col-1">
                <img alt="stripe" src={cubuk1} />
            </div>
            <div className="col-8">
              <div className="col-12 ">
                <h2>Paranı bilinçli harca, </h2>
                <h2>Çember Ahalisi ile  </h2>
                <h2>değişime <b>sen de</b> katıl!</h2>
                <p>Arkadaşlarını da birikim yapmaları için davet et, <a href="#hediyeler">ahaliye özel hediyeleri</a> kazan.
                </p>
               </div>

              
               <div className="col-12">
               
                <a className="cta" href="https://vkyd8fmviji.typeform.com/to/j4yq4tYp" rel="noreferrer" target="_blank">Katıl</a>
              
               </div>
            </div>
            <div className="col-1">
                <img alt="stripe" src={cubuk2} />
            </div>
            <div className="col-1 hidden-sm"></div>
            </div>
        </div>
        </div>
        <div className="row">
            <div className="container ahali-aciklama">
                <div className="container">
                <div className="col-12">
                    <h2>Ahali nedir?  <span role="img" aria-label="curious">🤔</span></h2>
                    <p><b>Çember Ahalisi</b>’nde birikim yapmanın gerekliliğine inananlar, 
                    Çember'i senin gibi para biriktirme yöntemi olarak kullananlar ve bu alışkanlığı oluşturduğu çemberlerle herkese yaymaya çalışanlar var. 
                    <span role="img" aria-label="emoji">🙌🏻</span>  Sen de borçlanarak değil biriktirerek harcamaya inanıyorsan aramıza katıl!
                    </p>
                </div>
                </div>
                
            </div>
        </div>
        <div class="slideshow">
            <div class="mover-1"></div>
        </div>

    <div className="row">
       <div className="hero-container">
        <div className="container">
          <div className="row">
             
            <div className="col-6">
            <div className="hero-text">
                <h2 data-sal-duration="400" data-sal="slide-right" data-sal-delay="200">
                Arkadaşlarını para biriktirmeye davet eden sen ol! 
                <span role="img" aria-label="emoji">🎉</span> </h2>
                <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="500">
                    Borçlanarak değil biriktirerek hayallerini gerçekleştirmek için Çember’i kullanıyorsan, sürüden ayrılmaya hazırsın. Çember ile birikim yapabilir, hayallerin için ihtiyaç duyduğun finansmana sahip olabilirsin. 
                </p>
                
            </div>
            </div>
            <div className="col-6 center">
                <img data-sal-duration="600" data-sal="slide-up" data-sal-delay="200"  src={surudenAyril} alt="Sürüden Ayrıl"/>      
            </div>
           
            <div className="col-12 center">
                <div className="col-4"></div>
                <div className="col-4">
                    <img data-sal-duration="600" data-sal="slide-up" data-sal-delay="200"  src={hediyelerKazan} alt="Hediyeler Kazan"/> 
                </div>
                <div className="col-4"></div>
            </div>
          </div>
          <div className="row ">
          <div className="col-6 center">
                <img data-sal-duration="600" data-sal="slide-up" data-sal-delay="200"  src={sanaOzel} alt="Sana Özel"/>      
            </div>
            <div className="col-6">
              <div className="hero-text">
                <h2 data-sal-duration="400" data-sal="slide-right" data-sal-delay="200">Sana özel hediyeler! 
                <span role="img" aria-label="emoji">🎁</span> </h2>
                <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="500">
                Ahali’nin bir parçası olarak, <span>sana özel</span> kampanyalardan faydalanabilirsin. 
                </p>
                <p>
                Davet ettiğin arkadaşların üye olduğunda bizden alacağın küçük özel tasarım hediyelerimize de hayır demeyeceğini umuyoruz!
                </p>
                
              </div>
            </div>
          </div>
        </div>  
      </div>
      </div>

      <div className="row hediye-nasil">
            <div className="container">
            <div className="col-12">
               <div>
                <h1>
                Hediyeleri nasıl kazanabilirsin?    
                </h1>
                <img   src={hediyeNasil} alt="Nasıl Hediye kazanabilirsin?"/>      
                <p>
                Arkadaşlarını davet et, davetinle gelen kişi sayısına 
göre hediyeni sana gönderelim!  <span role="img" aria-label="emoji">🙌🏻</span> 
                </p>
              </div>                 
                 
            </div>
            </div>
        </div>

      <div className="row hediyeler" id="hediyeler">
            <div className="container ">
                <div className="container">
                <div className="col-12">
                    <h2>Hediyeler  <span role="img" aria-label="curious">🎁</span></h2>
                    <ul>
                        <li>
                            <span>5 Kişi</span>
                            <p>2021 Hedefler Takvimi ve Sticker Seti</p>
                        </li>
                        <li>
                            <span>10 Kişi</span>
                            <p>Bilinçli Tüketicinin Alışveriş Çantası ve Sticker Seti</p>
                        </li>
                        <li>
                            <span>15 Kişi</span>
                            <p>Termos ve Sticker Seti</p>
                        </li>
                        <li>
                            <span>20 Kişi</span>
                            <p>Çember Defteri ve Sticker Seti</p>
                        </li>
                       


                    </ul>
                </div>
                </div>
                
            </div>
        </div>

        <div className="row hero-container hediye-grid">
            <div className="container">
            <div className="row ">
                <div className="col-6">
                <div className="hero-text">
                    <h3 data-sal-duration="400" data-sal="slide-right" data-sal-delay="0">2021 Hedefler Takvimi ve Sticker Seti
                    </h3>
                    <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="100">
                    2021 hayallerini görünür hale getirebilmen ve somut hedeflere dönüştürebilmen için bir takvim hazırladık.
                    <span role="img" aria-label="emoji">✨</span>  Bu sene için planların ve hedeflerinle birlikte her ay toplam birikimini de yazabileceğin bir bölüm var.
                    </p>
                    <h4 data-sal-duration="400" data-sal="slide-right" data-sal-delay="100">Nasıl kullanabilirsin? <span role="img" aria-label="emoji">👏🏼</span> </h4>
                    
                    <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="100"> Takvimi dijital olarak doldurabilirsin. PDF formatında bilgisayarında veya telefonunda saklayabilirsin.
                    <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="100"> Çıktı alıp evine, odana asabilirsin. Yazarak doldurmak çok daha eğlenceli. 
                    <span role="img" aria-label="emoji">✍🏻</span> </p>
                    </p>
                    
                </div>
                </div>
                <div className="col-6 center">
                    <img data-sal-duration="600" data-sal="slide-up" data-sal-delay="200"  src={hediye1} alt="5 kişi getirene hediye"/>      
                </div>
            </div>

            <div className="row ">
            <div className="col-6 center">
                    <img data-sal-duration="600" data-sal="slide-up" data-sal-delay="200"  src={hediye2} alt="10 kişi getirene hediye"/>      
                </div>
                <div className="col-6">
                <div className="hero-text">
                    <h3 data-sal-duration="400" data-sal="slide-right" data-sal-delay="0">
                    Bilinçli Tüketicinin Alışveriş Çantası ve Sticker Seti
                    </h3>
                    <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="100">
                    Bu alışveriş çantasıyla gerçekten ihtiyacın olanları al, bilinçli bir tüketici olma yolunda adım atmaya başla!
                    </p>
                    <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="100"> 
                    Kendin ve çevren için farkındalık yaratarak, alışverişlerinde poşet satın almak yerine bez çanta kullanarak da bilinçli tüketimi destekleyebilirsin. 
                    </p>
                    
                </div>
                </div>
               
            </div>

            <div className="row ">
                
                <div className="col-6">
                    <div className="hero-text">
                        <h3 data-sal-duration="400" data-sal="slide-right" data-sal-delay="0">
                        Termos ve Sticker Seti
                        </h3>
                        <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="100">
                        Her dışarıdan kahve aldığında kullandığın kağıt bardakların sayısı seni de üzmüyor mu? </p>
                        <p>
                        Kahveni evde kendi termosunda yap, hem kahveye harcadığın paradan tasarruf et hem de istediğin yerde lezzetli kahve iç! Sticker setinde termosuna yapıştırmaya uygun emojiler bulabilirsin. 
                        <span role="img" aria-label="emoji">✍🙌</span> 
                        </p>
                    </div>  
                </div>
                
                <div className="col-6 center">
                    <img data-sal-duration="600" data-sal="slide-up" data-sal-delay="200"  src={hediye3} alt="15 kişi getirene hediye"/>      
                </div>
            </div>

            <div className="row ">
                <div className="col-6 center">
                    <img data-sal-duration="600" data-sal="slide-up" data-sal-delay="200"  src={hediye4} alt="10 kişi getirene hediye"/>      
                </div>
                <div className="col-6">
                    <div className="hero-text">
                        <h3 data-sal-duration="400" data-sal="slide-right" data-sal-delay="0">
                        Çember Defteri ve Sticker Seti
                        </h3>
                        <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="100">
                        Hedeflerini ve hayallerini yazabileceğin, paranı daha iyi yönetmende sana yardımcı olacak bir defter hazırladık! Bu yıl defterini dolu dolu kullanmanı ve tüm hedeflerine ulaşmanı diliyoruz.
                        </p>
                        <p data-sal-duration="400" data-sal="slide-right" data-sal-delay="100">                   
                        En çok sevilen emojileren oluşan sticker setin defterinin arasında olacak! 
                         <span role="img" aria-label="emoji">✍🏻</span> 
                        </p>
                        
                    </div>
                </div>
            </div>

          </div>
        </div>

        <div className="row influencer">
            <div className="container">
            <div className="col-12">
                <div className="isbirligi">
                <h1><span role="img" aria-label="Emoji">💙</span>
             
                    Çember’i Paylaş                
                    
                <span role="img" aria-label="Emoji">💙</span>
                </h1>
                <b>Bize herkes influencer!</b>
                <p>
                Önemli olan sensin, takipçi sayın değil. Bizi arkadaşlarınla paylaş, Çember Ahali hediyelerinden kazan.
                  </p>
                 
                  <a className="cta" href="https://vkyd8fmviji.typeform.com/to/j4yq4tYp" rel="noreferrer" target="_blank">Katıl</a>
              
                  
             </div>
            </div>
            </div>
        </div>

        <div className="row">
    <div className="container">
      <div className="col-12">
        <div className="downloadkutu">
          <h1>Hemen indir, davet et ve hediyeler kazan!</h1>
          
          <div className="download-buttons">
                  <a onClick={e => {
                    //e.preventDefault()
                    trackCustomEvent({
                      category: "ios",
                      action: "click",
                      label: "community"
                    })
                  }} href="https://apps.apple.com/us/app/id1532621504" rel="noreferrer"  target="_blank"><img src={iosButton} alt="IOS indir"/></a>
                  <a onClick={e => {
                    //e.preventDefault()
                    trackCustomEvent({
                      category: "android",
                      action: "click",
                      label: "community"
                    })
                  }}  href="https://play.google.com/store/apps/details?id=ist.tio.cember" rel="noreferrer"  target="_blank"><img src={androidButton} alt="Android indir"/></a>
            </div>
           
          <img className="sabit-resim" src={soru} alt="Soru Sor"/>
        </div>
      </div>
    </div>
  </div>




        

      </div>
     

      


    </Layout>
  )
}

export default CommunityPage